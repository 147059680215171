import React from 'react';
import './ComingSoon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faXTwitter,
	faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import logo from './logo.jpeg';

function ComingSoon() {
	return (
		<div className="coming-soon-container">
			<div className="logo">
				<img src={logo} alt="Exquisitely Digital Logo" />
			</div>
			<p className="coming-soon-text">
				As a cutting-edge digital marketing agency, we're crafting
				something truly exquisite. Stay tuned for what's next!
			</p>

			<p className="launch-date">Launching Soon...</p>
			<div className="social-icons">
				<a
					href="https://www.facebook.com/people/Exquisitely-Digital/61566790538751/"
					className="social-icon"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FontAwesomeIcon icon={faFacebook} />
				</a>
				<a
					href="https://x.com/exquisitelydigi?s=21"
					className="social-icon"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FontAwesomeIcon icon={faXTwitter} />
				</a>
				<a
					href="https://www.instagram.com/exquisitelydigital?igsh=MWxmeTdueWR0azR3cg%3D%3D&utm_source=qr"
					className="social-icon"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FontAwesomeIcon icon={faInstagram} />
				</a>
			</div>
		</div>
	);
}

export default ComingSoon;
